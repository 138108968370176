<template>
  <component :is="deviceComponent" />
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue';

let deviceType = '';
const { isDesktop, isTablet } = useDevice();

if (isDesktop) {
  deviceType = "desktop";
} else if (isTablet) {
  deviceType = "tablet";
} else {
  deviceType = "mobile";
}
const routeName = useRoute().name.split('___')[0];

const componentMap = {
  'home': {
    mobile: () => import('~/pages/mobile/home/index.vue'),
    desktop: () => import('~/pages/desktop/home/index.vue'),
  },
  'listings.show': {
    mobile: () => import('~/pages/mobile/account/listings/show.vue'),
    desktop: () => import('~/pages/desktop/listings/show.vue'),
  },
  'listings.add': {
    mobile: () => import('~/pages/mobile/account/listings/add.vue'),
  },
  'listings.preview': {
    mobile: () => import('~/pages/mobile/account/listings/preview.vue'),
  },
  'listings.last_seen': {
    mobile: () => import('~/pages/mobile/account/listings/last-seen.vue'),
  },
  'teammate.index': {
    mobile: () => import('~/pages/mobile/teammates/index.vue'),
    desktop: () => import('~/pages/desktop/teammates/index.vue'),
  },
  'developers.index': {
    mobile: () => import('~/pages/mobile/developers/index.vue'),
    desktop: () => import('~/pages/desktop/developers/index.vue'),
  },
  'developers.show': {
    mobile: () => import('~/pages/mobile/developers/show.vue'),
    desktop: () => import('~/pages/desktop/developers/show.vue'),
  },
  'contact_us': {
    mobile: () => import('~/pages/mobile/contact/index.vue'),
    desktop: () => import('~/pages/desktop/contact/index.vue'),
  },
  'guides.index': {
    desktop: () => import('~/pages/desktop/guides/index.vue'),
  },
  'guides.show': {
    mobile: () => import('~/pages/mobile/guides/show.vue'),
    desktop: () => import('~/pages/desktop/guides/show.vue'),
  },
  'projects.index': {
    mobile: () => import('~/pages/mobile/projects/index.vue'),
    desktop: () => import('~/pages/desktop/projects/index.vue'),
  },
  'sitemap.index': {
    mobile: () => import('~/pages/mobile/sitemaps/index.vue'),
    desktop: () => import('~/pages/desktop/sitemaps/index.vue'),
  },
  'more': {
    mobile: () => import('~/pages/mobile/more/index.vue'),
  },
  'search': {
    mobile: () => import('~/pages/mobile/search/index.vue'),
  },
  'account.login': {
    mobile: () => import('~/pages/mobile/account/login/index.vue'),
  },
  'account.register': {
    mobile: () => import('~/pages/mobile/account/register/index.vue'),
  },
  'account.verify': {
    mobile: () => import('~/pages/mobile/account/verify-account/index.vue'),
  },
  'account.forget.password': {
    mobile: () => import('~/pages/mobile/account/forget-password/index.vue'),
  },
  'account.notifications': {
    mobile: () => import('~/pages/mobile/account/notifications/index.vue'),
  },
  'account.customers': {
    mobile: () => import('~/pages/mobile/account/customers/index.vue'),
  },
  'account.customers.create': {
    mobile: () => import('~/pages/mobile/account/customers/form/index.vue'),
  },
  'account.customers.edit': {
    mobile: () => import('~/pages/mobile/account/customers/form/index.vue'),
  },
  'account.customers.show': {
    mobile: () => import('~/pages/mobile/account/customers/show/index.vue'),
  },
  'account.offers.index': {
    mobile: () => import('~/pages/mobile/account/offers/index.vue'),
  },
  'account.offers.create': {
    mobile: () => import('~/pages/mobile/account/offers/form/index.vue'),
  },
  'account.offers.edit': {
    mobile: () => import('~/pages/mobile/account/offers/form/index.vue'),
  },
  'account.profile.index': {
    mobile: () => import('~/pages/mobile/account/profile/index.vue'),
  },
  'account.profile.change_email': {
    mobile: () => import('~/pages/mobile/account/profile/change-email.vue'),
  },
  'account.profile.change_password': {
    mobile: () => import('~/pages/mobile/account/profile/change-password.vue'),
  },
  'account.profile.change_name': {
    mobile: () => import('~/pages/mobile/account/profile/change-name.vue'),
  },
  'account.profile.mobile_phones': {
    mobile: () => import('~/pages/mobile/account/profile/mobile-phones.vue'),
  },
  'slug': {
    mobile: () => import('~/pages/mobile/slug/index.vue'),
    desktop: () => import('~/pages/desktop/slug/index.vue'),
  },
};

const deviceComponent = computed(() => {
  const componentLoader = componentMap[routeName]?.[deviceType];
  if (!componentLoader) {
    showError({
      statusCode: 404,
      statusMessage: "Page Not Found"
    })
  }
  return componentLoader ? defineAsyncComponent(componentLoader) : null;
});
</script>
